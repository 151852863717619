import React, { useState, ChangeEvent } from "react";
import Layout from "../components/layout";
import PageTitle from "../components/PageTitle";
import { TransitionContainer } from "../components/TransitionContainer";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Highlight } from "../components/Highlight";
import {
  Typography,
  Container,
  Box,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Grid,
} from "@material-ui/core";
import { ContactCard } from "../components/ContactCard";
import { graphql } from "gatsby";
import SEO from "../components/seo";

type IndexProps = {
  data: any;
};

export default ({ data }: IndexProps) => {
  const [expanded, setExpanded] = useState("panel1");

  const handleChange = (panel: string) => (
    _: ChangeEvent,
    expanded: boolean
  ) => {
    setExpanded(expanded ? panel : "");
  };
  return (
    <Layout>
      <SEO title="Impressum" />
      <Box marginTop={20}>
        <Container>
          <Box paddingTop={10} paddingBottom={10}>
            <Box marginBottom={3}>
              <Typography variant="h3" paragraph>
                Angaben gemäß § 5 TMG Inhaltlich Verantwortlicher (gemäß § 10
                Absatz 3 MDStV)
              </Typography>
            </Box>
            <Box marginBottom={6}>
              <Typography variant="h6" paragraph>
                Anschrift:
              </Typography>
              <Typography paragraph>
                Steuerberater Dipl.-FW (FH) Mehdi Tajdari
              </Typography>
              <Typography paragraph>
                Am Hopfengarten 7, 60489 Frankfurt
              </Typography>
              <Typography variant="h6" paragraph>
                Kontakt:
              </Typography>
              <Typography paragraph>Tel.: 017634558190</Typography>
              <Typography paragraph>E-Mail: info@tajdari.de</Typography>
              <Typography paragraph>
                Diplom-Finanzwirt (FH) Mehdi Tajdari ist Mitglied der
                Steuerberaterkammer Hessen, Bleichstraße 1, 60313 Frankfurt. Er
                führt die Berufsbezeichnung Steuerberater, die in der
                Bundesrepublik Deutschland verliehen wurde.
              </Typography>
              <Typography paragraph>
                Berufsrechtliche Regelungen sind einsehbar unter
                http://www.bstbk.de, Rubrik Steuerberater/Berufsrecht;
                insbesondere
              </Typography>
              <Typography paragraph>
                Steuerberatungsgesetz (StBerG), Durchführungsverordnung zum
                Steuerberatungsgesetz (DVStB), Berufsordnung der
                Bundessteuerberaterkammer.
              </Typography>
              <Typography paragraph>
                Inhaltlich Verantwortlicher gemäß: Dipl-Finanzwirt (FH) Mehdi
                Tajdari
              </Typography>
              <Typography paragraph>
                USt.-Id.-Nr. gemäß § 27 UStG: n/a
              </Typography>
              <Typography paragraph>
                Plattform der EU zur außergerichtlichen Online-Streitbeilegung:
                http://ec.europa.eu/consumers/odr/
              </Typography>
              <Typography variant="h6" paragraph>
                Haftung für Links
              </Typography>
              <Typography paragraph>
                Wir bemühen uns die Inhalte unserer Seite aktuell zu halten.
                Trotz sorgfältiger Bearbeitung bleibt eine Haftung
                ausgeschlossen.
              </Typography>
              <Typography paragraph>
                Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene
                Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
                verantwortlich.
              </Typography>
              <Typography paragraph>
                Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
                verpflichtet, übermittelte oder gespeicherte fremde
                Informationen zu überwachen. Bei bekannt werden von
                Rechtsverletzungen, werden wir diese Inhalte umgehend entfernen.
                Eine diesbezügliche Haftung übernehmen wir erst ab dem Zeitpunkt
                der Kenntnis einer möglichen Rechtsverletzung.
              </Typography>
              <Typography variant="h6" paragraph>
                Urheberrecht
              </Typography>
              <Typography paragraph>
                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
                diesen Seiten unterliegen dem deutschen Urheberrecht. Die
                Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
                schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
              </Typography>
              <Typography variant="h6" paragraph>
                Datenschutz
              </Typography>
              <Typography paragraph>
                Wir weisen darauf hin, dass die Datenübertragung im Internet
                (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken
                aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff
                durch Dritte ist nicht möglich. Eine Vertraulichkeit im Hinblick
                auf die Datenschutzbestimmungen wird nur unter der vorstehenden
                Einschränkung gewährleistet. Insbesondere sollen alle
                Mitteilungen von personenbezogenen Daten über das Internet nur
                erfolgen, soweit nicht Rechte Dritter berührt werden. Es sei
                denn der Dritte hat in Kenntnis der vorstehenden
                Sicherheitslücken ebenfalls seine Zustimmung erklärt. Eine
                Haftung des Seitenbetreibers wird für die durch solche
                Sicherheitslücken entstehenden Schäden oder
                Unterlassungsansprüche ausgeschlossen.
              </Typography>
              <Typography paragraph>
                Der Nutzung von allen veröffentlichten Kontaktdaten durch Dritte
                zur Übersendung von nicht ausdrücklich angeforderter Werbung
                wird widersprochen. Die Betreiber der Seiten behalten sich
                ausdrücklich rechtliche Schritte im Falle der unverlangten
                Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.
              </Typography>
              <Typography variant="h6" paragraph>
                Widerspruch Werbe-Mails
              </Typography>
              <Typography paragraph>
                Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
                Kontaktdaten zur Übersendung von nicht ausdrücklich
                angeforderter Werbung und Informationsmaterialien wird hiermit
                widersprochen. Die Betreiber der Seiten behalten sich
                ausdrücklich rechtliche Schritte im Falle der unverlangten
                Zusendung von Werbeinformationen, etwa durch Spam-E- Mails, vor.
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </Layout>
  );
};

export const query = graphql`
  query {
    file(relativePath: { eq: "mehdi-tajdari.jpg" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;
